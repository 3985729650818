.numeric-theme {
    max-width: 320px;
    margin: 0 auto;
}

.numeric-theme + .simple-keyboard-preview {
    max-width: 320px;
}

.hg-button {
    max-width: none;
    font-size: x-large !important;
}

.logosize {
    max-width: 150px;
    max-height: 150px;
}
